import { graphql, useStaticQuery } from 'gatsby';
import { AllDirectusAccreditation, AccreditationEdge } from './accreditations.interface';

export default (): AccreditationEdge[] => {
    const { allDirectusAccreditation } = useStaticQuery<AllDirectusAccreditation>(graphql`
    query {
      allDirectusAccreditation(sort: { fields: sort, order: ASC }) {
        edges {
          node {
            directusId
            sort
            slug
            logo {
              data {
                full_url
              }
              width
              height
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            logo_alt
            body
          }
        }
      }
    }
  `);

    return allDirectusAccreditation.edges;
};
