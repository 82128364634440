import React, { useState } from 'react';
import { Transition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus, faAngleDown } from '@fortawesome/pro-light-svg-icons';
import OutsideClickHandler from 'react-outside-click-handler';

import windowResizeHook from 'hooks/resize/resize.hook';
import { Props } from './DropDownList.interface';

import s from './DropDownList.scss';

export const DropDownList = ({ children, title, columns, isHome, dropDownCaret }: Props): JSX.Element => {
    const [ isOpen, setIsOpen ] = useState<boolean>(false);
    const dimensions = windowResizeHook();
    const screenSm = dimensions.width < 1150;

    const styles = {
        toggle: isHome ? s.dropdown__toggle___home : '',
        list: isHome ? s.list___home : '',
        column: columns > 1 ? s.dropdown__twoColumns : '',
    };

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const menu = isOpen ? {
        icon: faMinus,
    } : {
        icon: faPlus,
    };

    const burgerListOpen = {
        display: (isOpen && screenSm) ? 'flex' : '',
    };

    const defaultStyle = {
        transition: 'opacity 75ms ease-in-out',
        opacity: 0,
        display:'none',
    };

    const transitionStyles = {
        entering: { display: 'flex' },
        entered: { opacity: 1 , display: 'flex' },
        exiting: { opacity: 0.5 },
        exited: { opacity: 0, display: 'none' },
        unmounted: {},
    };

    return (
        <div
            className={`${s.dropdown} ${(isOpen && !isHome) ? s.dropdown___open : ''}`}
            onClick={(e: any) => toggleMenu(e)}
            role="presentation"
        >
            <OutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
                <div className={s.dropdown__button}>
                    <span className={s.dropdown__title}>
                        {title}
                        {(dropDownCaret && !screenSm) && <FontAwesomeIcon icon={faAngleDown} size="1x"/>}
                    </span>

                    <div className={`${s.dropdown__toggle} ${styles.toggle}`}>
                        <span className={s.dropdown__toggle__icon}>
                            <FontAwesomeIcon icon={menu.icon} title="toggle drop-down list" />
                        </span>
                    </div>
                </div>
                <Transition in={isOpen} timeout={75}>
                    {state => (
                        <ul
                            className={`${s.list} ${styles.column} ${styles.list}`}
                            style={{
                                ...burgerListOpen,
                                ...defaultStyle,
                                /* tslint:disable */
                                ...transitionStyles[ state ],
                                /* tslint:enable */
                            }}
                        >
                            {children}
                        </ul>
                    )}
                </Transition>
            </OutsideClickHandler>
        </div>
    );
};
