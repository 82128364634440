const favicon = require('assets/images/favicon.ico');
const share = require('assets/images/share.png');

const themeColor = '#00b1e3';

export const helmet = {
    titleTemplate: '%s | Rapid Formations',
    htmlAttributes: { lang: 'en' },
    meta: [
        { name: 'viewport', content: 'width=device-width, initial-scale=1, maximum-scale=5, user-scalable=no' },
        { name: 'apple-mobile-web-app-status-bar-style', content: 'black' },
        { name: 'msapplication-navbutton-color', content: themeColor },
        { name: 'msapplication-TileColor', content: themeColor },
        { name: 'theme-color', content: themeColor },

        // tslint:disable-next-line
        { name: 'geo.placename', content: 'Rapid Formations, 71-75, Shelton Street, Covent Garden, London, WC2H 9JQ' },
        { name: 'geo.position', content: '51.514705;-0.123609' },
        { name: 'geo.region', content: 'GB' },
        { name: 'ICBM', content: '51.514705;-0.123609' },

        { property: 'og:image', content: share },
        { property: 'og:image:width', content: '880px' },
        { property: 'og:image:height', content: '440px' },
        { property: 'og:locale', content: 'en_GB' },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: '/' },

        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:image', content: share },
        { name: 'twitter:site', content: '@rapidukofficial' },
        { name: 'twitter:creator', content: '@rapidukofficial' },
    ],
    link: [
        { rel: 'icon', type: 'image/x-icon', href: favicon },
    ],
};
