import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Markdown from 'react-markdown';
import shortcodes from 'remark-shortcodes';
import remarkSubSuper from 'remark-sub-super';

import Button from 'components/common/button/Button';
import { Link } from 'components/common/link/Link';
import Partners from 'components/directus/partners/Partners';
import priceHook from 'hooks/price/price.hook';
import reviewStatsHook from 'hooks/review-stats/review-stats.hook';
import { getFieldBySlug } from 'utils/directus';
import { externalCheck } from 'utils/external-check';
import { getSolidFontAwesomeIcon } from 'utils/font-awesome';
import { Props } from './Markdown.interface';

import s from './Markdown.scss';

export default ({ source, className, container, listItemIcon }: Props): JSX.Element => {
    const renderer = {
        link: ({ href, children }: any) => {
            if (externalCheck(href)) {
                return (
                    <a href={href} target="_blank" rel="follow noreferrer noopener">
                        {children}
                    </a>
                );
            }

            return <Link to={href}>{children}</Link>;
        },
        image: ({ src, alt }: any): any => <img className={s.markdownImage} alt={alt} src={src} />,
        list: ({ ordered, children }: any) => {
            if (ordered) {
                return <ol className={s.markdownList___ordered}>{children}</ol>;
            }

            if (listItemIcon) {
                return <ul className={s.markdownList}>{children}</ul>;
            }

            return <ul>{children}</ul>;
        },
        listItem: ({ children }: any) => {
            if (listItemIcon) {
                return (
                    <li className={s.markdownList__item}>
                        {listItemIcon} <span>{children}</span>
                    </li>
                );
            }

            return <li>{children}</li>;
        },
        shortcode: ({ identifier, attributes }: any) => {
            switch (identifier) {
            case 'fontawesome':
                return (
                    <FontAwesomeIcon
                        icon={getSolidFontAwesomeIcon(attributes.icon)}
                        color={attributes.color ? attributes.color : false}
                        size={attributes.size ? attributes.size : '1x'}
                    />
                );
            case 'price':
                return <>&pound;{getFieldBySlug(attributes.slug, priceHook()).node.value}</>;
            case 'button':
                return (
                    <Button
                        to={attributes.link}
                        role="secondary"
                    >
                        {attributes.text}
                    </Button>
                );
            case 'telephone':
                if (attributes.icon && attributes.icon === 'true') {
                    return (
                        <span className={s.markdownTelephone}>
                            <FontAwesomeIcon icon={getSolidFontAwesomeIcon('faPhoneAlt')} />
                            020 7871 9990
                        </span>
                    );
                }
                return <>020 7871 9990</>;
            case 'break':
                return <br />;
            case 'reviews':
                switch (attributes.type) {
                case 'reviews':
                    return getFieldBySlug(attributes.slug, reviewStatsHook()).node.total_reviews;
                case 'score':
                    return getFieldBySlug(attributes.slug, reviewStatsHook()).node.score;
                case 'maxscore':
                    return getFieldBySlug(attributes.slug, reviewStatsHook()).node.max_score;
                default:
                    return null;
                }
            case 'box':
                return (
                    <span className={s.markdownBox} style={{ backgroundColor:
                        attributes.color ? attributes.color : '#00ACE8',
                    }}>
                        {attributes.text}
                    </span>
                );
            case 'partners':
                return (
                    <Partners />
                );
                break;
            default: return <code>Shortcode {identifier} is missing required attribute(s).</code>;
            }
        },
        sub: 'sub',
        sup: 'sup',
    };

    if (container) {
        return (
            <Markdown
                source={source}
                className={className}
                renderers={renderer}
                plugins={[
                    [ shortcodes, { startBlock: '[[', endBlock: ']]', inlineMode: true } ],
                    [ remarkSubSuper ],
                ]}
            />
        );
    }

    return (
        <Markdown
            source={source}
            className={className}
            disallowedTypes={[ 'paragraph', 'heading' ]}
            unwrapDisallowed
            renderers={renderer}
            plugins={[
                [ shortcodes, { startBlock: '[[', endBlock: ']]', inlineMode: true } ],
                [ remarkSubSuper ],
            ]}
        />
    );
};
