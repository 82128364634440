import React from 'react';

import partnersHook from 'hooks/partners/partners.hook';
import { Partner } from './Partners.interface';

import s from './Partners.scss';

export default (): JSX.Element => {
    const partners = partnersHook();

    return (
        <div className={s.partners}>
            {partners.map(({ node }: Partner) => (
                <div key={`partner-${node.slug}`} className={s.partners__partner}>
                    <img className={s.partners__logo} src={node.logo.data.full_url} alt={`${node.name} partner logo.`} />
                </div>
            ))}
        </div>
    );
};
