export function getItemsByPage(slug: string, fields: any[]): any {
    if (!slug) {
        throw new Error('Slug is required to fetch fields.');
    }

    const items = fields.filter(({ node }: any) =>
        node.page.slug === slug,
    );

    if (items === undefined) {
        throw new Error(`Collection fields with page slug "${slug}" do not exist.`);
    }

    return items;
}
