import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare, faLinkedin, faTwitterSquare, IconDefinition } from '@fortawesome/free-brands-svg-icons';

import socialIconHook from 'hooks/social-icon/social-icon.hook';
import { getFieldBySlug } from 'utils/directus';
import { Props } from './SocialIcon.interface';

export const SocialIcon = ({ slug, className }: Props): JSX.Element => {
    const { node } = getFieldBySlug(slug, socialIconHook());
    const iconLookup = (slug: string): IconDefinition => {
        switch (slug) {
        case 'facebook': return faFacebookSquare;
        case 'twitter': return faTwitterSquare;
        case 'linkedin': return faLinkedin;
        default: throw new Error(`No icon exists for the slug: ${slug}`);
        }
    };

    return (
        <a href={node.url} className={className} title={`Social icon for ${slug}`} aria-label={`Social icon for ${slug}`}>
            <FontAwesomeIcon icon={iconLookup(node.slug)} color={node.icon_color} size="2x" />
        </a>
    );
};
