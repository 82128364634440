import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createPortal } from 'react-dom';

import mobileCheck from 'utils/mobile-check';
import { getSolidFontAwesomeIcon } from 'utils/font-awesome';
import { Link } from 'components/common/link/Link';

import { CallUsProps } from './MobileCallUs.interface';

import s from './MobileCallUs.scss';

const CallUs = ({ children, portalRoot, el }: CallUsProps): JSX.Element => {
    useEffect(() => {
        portalRoot.appendChild(el);

        return () => {
            if (portalRoot) {
                portalRoot.removeChild(el);
            }
        };
    });

    return createPortal(children, el);
};

export default (): JSX.Element => {
    const portalRoot = typeof document !== 'undefined' ? document.getElementById('portal') : null;
    const el = typeof document !== 'undefined' ? document.createElement('div') : null;
    if (!el || typeof window === undefined || typeof navigator === undefined || !mobileCheck()) {
        return <></>;
    }

    return (
        <CallUs el={el} portalRoot={portalRoot}>
            <button className={`${s.callUs}`} role="primary" type="button">
                <span className={s.callUs__link}>
                    <Link to="tel:02078719990" alt="Call Us via Phone">
                        <div className={s.callUs__icon}>
                            <FontAwesomeIcon icon={getSolidFontAwesomeIcon('faPhone')} />
                        </div>
            Call Now
                    </Link>
                </span>
            </button>
        </CallUs>
    );
};
