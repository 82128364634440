import React from 'react';
import CookieConsent from 'react-cookie-consent';

import { Link } from '../link/Link';

import s from './CookieConsent.scss';

export default(): JSX.Element => (
    <CookieConsent
        cookieName="accept-cookies"
        containerClasses={s.cookieConsent}
        buttonClasses={s.cookieConsent__button}
        expires={365}
    >
        This website uses cookies to ensure you get the best experience on our website.
        &nbsp;<Link to="/cookies-policy/">Learn more</Link>{' '}
    </CookieConsent>
);
