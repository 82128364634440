import { graphql, useStaticQuery } from 'gatsby';
import { AllDirectusNavigation, Navigation } from './navigation.interface';

export default (): Navigation[] => {
    const { allDirectusNavigation } = useStaticQuery<AllDirectusNavigation>(graphql`
    query {
      allDirectusNavigation(sort: { fields: sort, order: ASC }) {
        edges {
          node {
            directusId
            text
            link
            placement
            column_number
            columns {
              directusId
              slug
              items {
                directusId
                sort
                text
                link
                style_toggle
              }
            }
          }
        }
      }
    }
  `);

    return allDirectusNavigation.edges;
};
