// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-additional-services-business-address-tsx": () => import("./../../../src/pages/additional-services/business-address.tsx" /* webpackChunkName: "component---src-pages-additional-services-business-address-tsx" */),
  "component---src-pages-additional-services-london-registered-office-tsx": () => import("./../../../src/pages/additional-services/london-registered-office.tsx" /* webpackChunkName: "component---src-pages-additional-services-london-registered-office-tsx" */),
  "component---src-pages-additional-services-scottish-registered-office-tsx": () => import("./../../../src/pages/additional-services/scottish-registered-office.tsx" /* webpackChunkName: "component---src-pages-additional-services-scottish-registered-office-tsx" */),
  "component---src-pages-additional-services-service-address-tsx": () => import("./../../../src/pages/additional-services/service-address.tsx" /* webpackChunkName: "component---src-pages-additional-services-service-address-tsx" */),
  "component---src-pages-address-services-tsx": () => import("./../../../src/pages/address-services.tsx" /* webpackChunkName: "component---src-pages-address-services-tsx" */),
  "component---src-pages-apostilled-documents-tsx": () => import("./../../../src/pages/apostilled-documents.tsx" /* webpackChunkName: "component---src-pages-apostilled-documents-tsx" */),
  "component---src-pages-business-banking-anna-tsx": () => import("./../../../src/pages/business-banking/anna.tsx" /* webpackChunkName: "component---src-pages-business-banking-anna-tsx" */),
  "component---src-pages-business-banking-barclays-tsx": () => import("./../../../src/pages/business-banking/barclays.tsx" /* webpackChunkName: "component---src-pages-business-banking-barclays-tsx" */),
  "component---src-pages-business-banking-cashplus-tsx": () => import("./../../../src/pages/business-banking/cashplus.tsx" /* webpackChunkName: "component---src-pages-business-banking-cashplus-tsx" */),
  "component---src-pages-business-banking-countingup-tsx": () => import("./../../../src/pages/business-banking/countingup.tsx" /* webpackChunkName: "component---src-pages-business-banking-countingup-tsx" */),
  "component---src-pages-business-telephone-tsx": () => import("./../../../src/pages/business-telephone.tsx" /* webpackChunkName: "component---src-pages-business-telephone-tsx" */),
  "component---src-pages-certificate-of-good-standing-tsx": () => import("./../../../src/pages/certificate-of-good-standing.tsx" /* webpackChunkName: "component---src-pages-certificate-of-good-standing-tsx" */),
  "component---src-pages-company-address-guide-tsx": () => import("./../../../src/pages/company-address-guide.tsx" /* webpackChunkName: "component---src-pages-company-address-guide-tsx" */),
  "component---src-pages-company-dissolution-tsx": () => import("./../../../src/pages/company-dissolution.tsx" /* webpackChunkName: "component---src-pages-company-dissolution-tsx" */),
  "component---src-pages-company-name-change-tsx": () => import("./../../../src/pages/company-name-change.tsx" /* webpackChunkName: "component---src-pages-company-name-change-tsx" */),
  "component---src-pages-compare-packages-tsx": () => import("./../../../src/pages/compare-packages.tsx" /* webpackChunkName: "component---src-pages-compare-packages-tsx" */),
  "component---src-pages-confirmation-statement-service-tsx": () => import("./../../../src/pages/confirmation-statement-service.tsx" /* webpackChunkName: "component---src-pages-confirmation-statement-service-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-cookies-policy-tsx": () => import("./../../../src/pages/cookies-policy.tsx" /* webpackChunkName: "component---src-pages-cookies-policy-tsx" */),
  "component---src-pages-customer-reviews-tsx": () => import("./../../../src/pages/customer-reviews.tsx" /* webpackChunkName: "component---src-pages-customer-reviews-tsx" */),
  "component---src-pages-director-appointment-resignation-tsx": () => import("./../../../src/pages/director-appointment-resignation.tsx" /* webpackChunkName: "component---src-pages-director-appointment-resignation-tsx" */),
  "component---src-pages-dormant-company-accounts-tsx": () => import("./../../../src/pages/dormant-company-accounts.tsx" /* webpackChunkName: "component---src-pages-dormant-company-accounts-tsx" */),
  "component---src-pages-entrepreneur-scholarship-programme-tsx": () => import("./../../../src/pages/entrepreneur-scholarship-programme.tsx" /* webpackChunkName: "component---src-pages-entrepreneur-scholarship-programme-tsx" */),
  "component---src-pages-faqs-after-company-formation-tsx": () => import("./../../../src/pages/faqs/after-company-formation.tsx" /* webpackChunkName: "component---src-pages-faqs-after-company-formation-tsx" */),
  "component---src-pages-faqs-basics-tsx": () => import("./../../../src/pages/faqs/basics.tsx" /* webpackChunkName: "component---src-pages-faqs-basics-tsx" */),
  "component---src-pages-faqs-company-secretary-tsx": () => import("./../../../src/pages/faqs/company-secretary.tsx" /* webpackChunkName: "component---src-pages-faqs-company-secretary-tsx" */),
  "component---src-pages-faqs-confirmation-statement-tsx": () => import("./../../../src/pages/faqs/confirmation-statement.tsx" /* webpackChunkName: "component---src-pages-faqs-confirmation-statement-tsx" */),
  "component---src-pages-faqs-corporation-tax-tsx": () => import("./../../../src/pages/faqs/corporation-tax.tsx" /* webpackChunkName: "component---src-pages-faqs-corporation-tax-tsx" */),
  "component---src-pages-faqs-directors-service-address-tsx": () => import("./../../../src/pages/faqs/directors-service-address.tsx" /* webpackChunkName: "component---src-pages-faqs-directors-service-address-tsx" */),
  "component---src-pages-faqs-directors-tsx": () => import("./../../../src/pages/faqs/directors.tsx" /* webpackChunkName: "component---src-pages-faqs-directors-tsx" */),
  "component---src-pages-faqs-index-tsx": () => import("./../../../src/pages/faqs/index.tsx" /* webpackChunkName: "component---src-pages-faqs-index-tsx" */),
  "component---src-pages-faqs-limited-by-guarantee-tsx": () => import("./../../../src/pages/faqs/limited-by-guarantee.tsx" /* webpackChunkName: "component---src-pages-faqs-limited-by-guarantee-tsx" */),
  "component---src-pages-faqs-limited-by-shares-tsx": () => import("./../../../src/pages/faqs/limited-by-shares.tsx" /* webpackChunkName: "component---src-pages-faqs-limited-by-shares-tsx" */),
  "component---src-pages-faqs-limited-liability-partnerships-tsx": () => import("./../../../src/pages/faqs/limited-liability-partnerships.tsx" /* webpackChunkName: "component---src-pages-faqs-limited-liability-partnerships-tsx" */),
  "component---src-pages-faqs-registered-office-tsx": () => import("./../../../src/pages/faqs/registered-office.tsx" /* webpackChunkName: "component---src-pages-faqs-registered-office-tsx" */),
  "component---src-pages-faqs-shares-and-shareholders-tsx": () => import("./../../../src/pages/faqs/shares-and-shareholders.tsx" /* webpackChunkName: "component---src-pages-faqs-shares-and-shareholders-tsx" */),
  "component---src-pages-faqs-the-formation-process-tsx": () => import("./../../../src/pages/faqs/the-formation-process.tsx" /* webpackChunkName: "component---src-pages-faqs-the-formation-process-tsx" */),
  "component---src-pages-faqs-vat-tsx": () => import("./../../../src/pages/faqs/vat.tsx" /* webpackChunkName: "component---src-pages-faqs-vat-tsx" */),
  "component---src-pages-full-company-secretary-tsx": () => import("./../../../src/pages/full-company-secretary.tsx" /* webpackChunkName: "component---src-pages-full-company-secretary-tsx" */),
  "component---src-pages-go-digital-tsx": () => import("./../../../src/pages/go-digital.tsx" /* webpackChunkName: "component---src-pages-go-digital-tsx" */),
  "component---src-pages-help-centre-a-z-glossary-of-terms-tsx": () => import("./../../../src/pages/help-centre/a-z-glossary-of-terms.tsx" /* webpackChunkName: "component---src-pages-help-centre-a-z-glossary-of-terms-tsx" */),
  "component---src-pages-help-centre-online-admin-portal-tsx": () => import("./../../../src/pages/help-centre/online-admin-portal.tsx" /* webpackChunkName: "component---src-pages-help-centre-online-admin-portal-tsx" */),
  "component---src-pages-help-centre-required-information-tsx": () => import("./../../../src/pages/help-centre/required-information.tsx" /* webpackChunkName: "component---src-pages-help-centre-required-information-tsx" */),
  "component---src-pages-help-centre-steps-to-forming-a-company-tsx": () => import("./../../../src/pages/help-centre/steps-to-forming-a-company.tsx" /* webpackChunkName: "component---src-pages-help-centre-steps-to-forming-a-company-tsx" */),
  "component---src-pages-id-requirements-tsx": () => import("./../../../src/pages/id-requirements.tsx" /* webpackChunkName: "component---src-pages-id-requirements-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-issue-of-shares-tsx": () => import("./../../../src/pages/issue-of-shares.tsx" /* webpackChunkName: "component---src-pages-issue-of-shares-tsx" */),
  "component---src-pages-name-check-all-inclusive-package-tsx": () => import("./../../../src/pages/name-check-all-inclusive-package.tsx" /* webpackChunkName: "component---src-pages-name-check-all-inclusive-package-tsx" */),
  "component---src-pages-name-check-compliance-package-tsx": () => import("./../../../src/pages/name-check-compliance-package.tsx" /* webpackChunkName: "component---src-pages-name-check-compliance-package-tsx" */),
  "component---src-pages-name-check-contractors-package-tsx": () => import("./../../../src/pages/name-check-contractors-package.tsx" /* webpackChunkName: "component---src-pages-name-check-contractors-package-tsx" */),
  "component---src-pages-name-check-digital-package-tsx": () => import("./../../../src/pages/name-check-digital-package.tsx" /* webpackChunkName: "component---src-pages-name-check-digital-package-tsx" */),
  "component---src-pages-name-check-digital-plus-package-tsx": () => import("./../../../src/pages/name-check-digital-plus-package.tsx" /* webpackChunkName: "component---src-pages-name-check-digital-plus-package-tsx" */),
  "component---src-pages-name-check-lbg-package-tsx": () => import("./../../../src/pages/name-check-lbg-package.tsx" /* webpackChunkName: "component---src-pages-name-check-lbg-package-tsx" */),
  "component---src-pages-name-check-llp-package-tsx": () => import("./../../../src/pages/name-check-llp-package.tsx" /* webpackChunkName: "component---src-pages-name-check-llp-package-tsx" */),
  "component---src-pages-name-check-non-residents-package-tsx": () => import("./../../../src/pages/name-check-non-residents-package.tsx" /* webpackChunkName: "component---src-pages-name-check-non-residents-package-tsx" */),
  "component---src-pages-name-check-non-residents-plus-package-tsx": () => import("./../../../src/pages/name-check-non-residents-plus-package.tsx" /* webpackChunkName: "component---src-pages-name-check-non-residents-plus-package-tsx" */),
  "component---src-pages-package-all-inclusive-package-tsx": () => import("./../../../src/pages/package/all-inclusive-package.tsx" /* webpackChunkName: "component---src-pages-package-all-inclusive-package-tsx" */),
  "component---src-pages-package-compliance-package-tsx": () => import("./../../../src/pages/package/compliance-package.tsx" /* webpackChunkName: "component---src-pages-package-compliance-package-tsx" */),
  "component---src-pages-package-digital-package-tsx": () => import("./../../../src/pages/package/digital-package.tsx" /* webpackChunkName: "component---src-pages-package-digital-package-tsx" */),
  "component---src-pages-package-digital-plus-package-tsx": () => import("./../../../src/pages/package/digital-plus-package.tsx" /* webpackChunkName: "component---src-pages-package-digital-plus-package-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-refund-cancellation-policy-tsx": () => import("./../../../src/pages/refund-cancellation-policy.tsx" /* webpackChunkName: "component---src-pages-refund-cancellation-policy-tsx" */),
  "component---src-pages-renewals-tsx": () => import("./../../../src/pages/renewals.tsx" /* webpackChunkName: "component---src-pages-renewals-tsx" */),
  "component---src-pages-scottish-company-formation-tsx": () => import("./../../../src/pages/scottish-company-formation.tsx" /* webpackChunkName: "component---src-pages-scottish-company-formation-tsx" */),
  "component---src-pages-site-map-tsx": () => import("./../../../src/pages/site-map.tsx" /* webpackChunkName: "component---src-pages-site-map-tsx" */),
  "component---src-pages-terms-and-conditions-complaints-procedure-tsx": () => import("./../../../src/pages/terms-and-conditions/complaints-procedure.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-complaints-procedure-tsx" */),
  "component---src-pages-terms-and-conditions-index-tsx": () => import("./../../../src/pages/terms-and-conditions/index.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-index-tsx" */),
  "component---src-pages-transfer-of-shares-tsx": () => import("./../../../src/pages/transfer-of-shares.tsx" /* webpackChunkName: "component---src-pages-transfer-of-shares-tsx" */),
  "component---src-pages-vat-registration-tsx": () => import("./../../../src/pages/vat-registration.tsx" /* webpackChunkName: "component---src-pages-vat-registration-tsx" */)
}

