export const cookieOptions = (maxAge = 3600): {
    maxAge: number;
    path: string;
    domain?: string;
} => {
    if (typeof window !== 'undefined' && window.location.hostname === 'localhost') {
        return {
            maxAge,
            path: '/',
        };
    }

    return {
        maxAge,
        path: '/',
        domain: 'rapidformations.co.uk',
    };
};
