export const externalCheck = (link: string): boolean => {
    const sameDomain = 'rapidformations.co.uk';
    const trustpilotDomain = 'trustpilot.com';

    if ((link.includes('http') && !link.includes(sameDomain) )
     || link.includes('mailto') || link.includes('tel:')
     || link.includes(trustpilotDomain)) {
        return true;
    }
    return false;
};
