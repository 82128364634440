import { graphql, useStaticQuery } from 'gatsby';
import { AllDirectusCard, CardsEdge } from './cards.interface';

export default (): CardsEdge[] => {
    const { allDirectusCard } = useStaticQuery<AllDirectusCard>(graphql`
    query {
      allDirectusCard {
        edges {
          node {
            directusId
            slug
            logo {
              data {
                full_url
              }
              width
              height
            }
            logo_alt
          }
        }
      }
    }
  `);

    return allDirectusCard.edges;
};
