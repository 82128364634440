import React from 'react';

import { Link } from 'components/common/link/Link';
import { capitalize } from 'utils/capitalize';
import { externalCheck } from 'utils/external-check';
import { Props } from './Button.interface';

import s from './Button.scss';

export default ({
    to, ariaLabel, children, size, role, className, block, onClick, type = 'button', ...props
}: Props): JSX.Element => {
    const roleClass = role ? s[ `btn${capitalize(role)}` ] : '';
    const sizeClass = size ? s[ `btn${capitalize(size)}` ] : '';
    const blockClass = block ? s.btnBlock : '';

    if (to !== undefined) {
        if (externalCheck(to)) {
            return (
                <a
                    className={`${s.btn} ${className || ''} ${blockClass} ${sizeClass} ${roleClass}`}
                    href={to}
                    target={to.indexOf('tel') === -1 ? '_blank' : ''}
                    rel="nofollow noreferrer noopener"
                    aria-label={ariaLabel}
                >
                    {children}
                </a>
            );
        }
        return (
            <Link
                to={to}
                className={`${s.btn} ${className || ''} ${blockClass} ${sizeClass} ${roleClass}`}
                {...props}
                aria-label={ariaLabel}
            >
                {children}
            </Link>
        );
    }

    return (
        <button
            className={`${s.btn} ${className || ''} ${blockClass} ${sizeClass} ${roleClass}`}
            onClick={onClick}
            aria-label={ariaLabel}
            // eslint-disable-next-line react/button-has-type
            type={type}
        >
            {children}
        </button>
    );
};
