import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/pro-light-svg-icons';

import { Props } from './DropDownList.interface';

import s from './DropDownList.scss';

export const DropDownList = ({ children, title, type }: Props): JSX.Element => {
    const [ isToggled, updateIsToggled ] = useState<boolean>(false);
    const toggleDropdown = (): void => updateIsToggled(! isToggled);

    const rootStyle = type === 'footer'
        ? s.dropDown
        : `${s.dropDown} ${s.dropDown___default}`;

    const buttonStyle = type === 'footer'
        ? s.dropDown__button
        : `${s.dropDown__button} ${s.dropDown__button___default}`;

    return (
        <div className={rootStyle}>
            <div onClick={toggleDropdown} className={buttonStyle} role="presentation">
                <h5 className={s.dropDown__title}>
                    {title}
                </h5>

                <div className={s.dropDown__toggle}>
                    <span className={s.dropDown__toggle__icon}>
                        <FontAwesomeIcon icon={isToggled ? faMinus : faPlus} title="toggle drop-down list" />
                    </span>
                </div>
            </div>

            <ul className={`${s.list} ${isToggled ? s.list__open : s.list__closed}`}>
                {children}
            </ul>
        </div>
    );
};
