import {
    faAngleRight,
    faBadgeCheck,
    faBoxFull,
    faCalendarTimes,
    faCartArrowDown,
    faCashRegister,
    faCheck,
    faChevronRight,
    faClipboardListCheck,
    faClock,
    faComments,
    faDesktop,
    faDotCircle,
    faFlag,
    faFile,
    faFileAlt,
    faFileArchive,
    faFileChartLine,
    faFileEdit,
    faFileExcel,
    faGift,
    faGlobeAmericas,
    faGraduationCap,
    faHeart,
    faLock,
    faMapMarker,
    faMapMarkerAlt,
    faMoneyCheckEdit,
    faPhone,
    faPhoneAlt,
    faPhoneRotary,
    faPlusCircle,
    faPrint,
    faSearch,
    faShare,
    faShieldAlt,
    faShieldCheck,
    faShoppingCart,
    faThumbsUp,
    faUniversity,
    faUser,
    faUserPlus,
    faUsers,
    faWindowAlt,
    IconDefinition,
} from '@fortawesome/pro-light-svg-icons';

export const getLightFontAwesomeIcon = (name: string): IconDefinition => {
    switch (name) {
    case 'faAngleRight': return faAngleRight;
    case 'faBadgeCheck': return faBadgeCheck;
    case 'faBoxFull': return faBoxFull;
    case 'faCalendarTimes': return faCalendarTimes;
    case 'faCartArrowDown': return faCartArrowDown;
    case 'faCashRegister': return faCashRegister;
    case 'faCheck': return faCheck;
    case 'faChevronRight': return faChevronRight;
    case 'faClipboardListCheck': return faClipboardListCheck;
    case 'faClock': return faClock;
    case 'faComments': return faComments;
    case 'faDesktop': return faDesktop;
    case 'faDotCircle': return faDotCircle;
    case 'faFlag': return faFlag;
    case 'faFile': return faFile;
    case 'faFileAlt': return faFileAlt;
    case 'faFileArchive': return faFileArchive;
    case 'faFileChartLine': return faFileChartLine;
    case 'faFileEdit': return faFileEdit;
    case 'faFileExcel': return faFileExcel;
    case 'faGift': return faGift;
    case 'faGlobeAmericas': return faGlobeAmericas;
    case 'faGraduationCap': return faGraduationCap;
    case 'faHeart': return faHeart;
    case 'faLock': return faLock;
    case 'faMapMarker': return faMapMarker;
    case 'faMapMarkerAlt': return faMapMarkerAlt;
    case 'faMoneyCheckEdit': return faMoneyCheckEdit;
    case 'faPhone': return faPhone;
    case 'faPhoneAlt': return faPhoneAlt;
    case 'faPhoneRotary': return faPhoneRotary;
    case 'faPlusCircle': return faPlusCircle;
    case 'faPrint': return faPrint;
    case 'faSearch': return faSearch;
    case 'faShare': return faShare;
    case 'faShieldAlt': return faShieldAlt;
    case 'faShieldCheck': return faShieldCheck;
    case 'faShoppingCart': return faShoppingCart;
    case 'faThumbsUp': return faThumbsUp;
    case 'faUniversity': return faUniversity;
    case 'faUser': return faUser;
    case 'faUserPlus': return faUserPlus;
    case 'faUsers': return faUsers;
    case 'faWindowAlt': return faWindowAlt;
    default: throw new Error(`No icon exists with the name: ${name}.`);
    }
};
