import React, { CSSProperties } from 'react';

import { Props } from './BurgerMenuIcon.interface';

const DEFAULT_PROPS: Required<Props> = {
    width: 40,
    height: 20,
    open: false,
    strokeWidth: 3,
    animationDuration: 0.4,
    color: '#626262',
};

export default (props: Props): JSX.Element => {
    const opts = Object.assign(DEFAULT_PROPS, props);
    const { color } = props;

    const getTransformValue = (
        isOpen: boolean,
        basePos: number,
        rotate: number,
        halfHeight: number,
    ): string =>
        `translate3d(0, ${isOpen ? `${halfHeight}px` : `${basePos}px`}, 0) rotate(${isOpen ? `${rotate}deg` : '0'})`;

    const halfHeight: number = Math.round(opts.height / 2);
    const halfStrokeWidth = Math.round(opts.strokeWidth / 2);

    const containerStyle: CSSProperties = {
        width: opts.width,
        height: opts.height,
        position: 'relative',
    };

    const baseStyle = {
        display: 'block',
        height: `${opts.strokeWidth}px`,
        width: '100%',
        background: color || '#000',
        transitionTimingFunction: 'ease',
        transitionDuration: `${opts.animationDuration}s`,
        transformOrigin: 'center',
        position: 'absolute',
        marginTop: -halfStrokeWidth,
    };

    const firstLineStyle = {
        transform: getTransformValue(opts.open, 0, 45, halfHeight),
    };

    const secondLineStyle = {
        transitionTimingFunction: 'ease-out',
        transitionDuration: `${opts.animationDuration / 4}s`,
        opacity: opts.open ? 0 : 1,
        top: halfHeight,
    };

    const thirdLineStyle = {
        transform: getTransformValue(opts.open, opts.height, -45, halfHeight),
    };

    return (
        <div style={containerStyle}>
            <span style={({ ...baseStyle, ...firstLineStyle })} />
            <span style={({ ...baseStyle, ...secondLineStyle })} />
            <span style={({ ...baseStyle, ...thirdLineStyle })} />
        </div>
    );
};
