import React from 'react';
import { Helmet } from 'react-helmet';

import CookieConsent from 'components/common/cookie-consent/CookieConsent';
import Navigation from 'components/directus/navigation/Navigation';
import MobileCallUs from 'components/common/mobile-callus/MobileCallUs';
import { Footer } from 'components/directus/footer/Footer';
import { helmet } from 'utils/helmet';
import { Props } from '../Layouts.interface';

import s from '../Layouts.scss';

export default ({ children }: Props): JSX.Element => (
    <>
        <div className={s.app}>
            <div>
                <Navigation isHome={false} />
                {children}
                <Helmet {...helmet} />
                <Footer />
                <CookieConsent />
                <MobileCallUs />
            </div>
        </div>
    </>
);
