import React, { useState, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneSquareAlt, faLock } from '@fortawesome/pro-solid-svg-icons';
import slugify from 'react-slugify';
import { connect } from 'react-redux';
import { useCookies } from 'react-cookie';

import { Link } from 'components/common/link/Link';
import { Wrapper } from 'components/common/wrapper/Wrapper';
import BurgerMenuIcon from 'components/directus/burger-menu-icon/BurgerMenuIcon';
import headerHook from 'hooks/header/header.hook';
import navigationHook from 'hooks/navigation/navigation.hook';
import { isAuthenticated } from 'services';
import { url } from 'utils/url';
import { StoreProps } from 'store/reducers/namecheck/namecheck.interface';

import { Props, NavColumn, NavItem } from './Navigation.interface';
import { DropDownList } from './list/DropDownList';
import { ListItemLink } from './list-item-link/ListItemLink';

import s from './Navigation.scss';

const Navigation = ({ isHome, dispatch }: Props): JSX.Element => {
    const { node } = headerHook()[ 0 ];
    const navigationItems = navigationHook();
    const [ isMenuOpen, updateIsMenuOpen ] = useState<boolean>(false);
    const [ cookies, setCookie, removeCookie ] = useCookies();

    const cookie = typeof window !== 'undefined' && window.document ? document.cookie : '';

    const source = useMemo(
        () => ({
            navListToggle: isHome ? `${s.header__navlist___home}` : `${s.header__navlist}`,
            navTabToggle: isHome ? `${s.header__navtab___home}` : `${s.header__navtab}`,
            contactInfoToggle: isHome ? `${s.contact__home}` : '',
            logoToggle: isHome ? node.logo_home : node.logo,
            loginToggle: isHome ? `${s.burger__button___home}` : '',
            headerToggle: isMenuOpen && isHome ? `${s.header__overlay}` : '',
            boxShadowToggle: isHome ? `${s.header___home}` : '',
            bottomWrap: isHome ? `${s.header__bottom__wrap___home}` : '',
            burgerColor: isHome ? '#ffffff' : '#000000',
        }),
        [ isHome, isMenuOpen ],
    );

    const left: any[] = [];
    const right: any[] = [];

    const clearNamecheck = (): void => {
        dispatch({ type: 'CLEAR_ALL' });
        removeCookie('company-name');
    };

    navigationItems.map(({ node }): void => {
        if (node.placement === 'Left') left.push(node);
        if (node.placement === 'Right') right.push(node);
        return null;
    });

    return (
        <header className={`${s.header} ${source.headerToggle} ${source.boxShadowToggle}`}>
            <Wrapper className={s.wrapper}>
                <div className={s.header__top}>
                    <div className={s.logo}>
                        <Link
                            to="/"
                            className={s.logo__link}
                            onClick={(): void => {
                                clearNamecheck();
                            }}
                        >
                            <img
                                src={source.logoToggle.data.full_url}
                                alt={node.logo_alt}
                                className={s.logo__link__image}
                                loading="eager"
                                style={{ maxWidth: '20rem' }}
                            />
                        </Link>
                    </div>

                    <div className={s.contact}>
                        <div className={s.contact__container}>
                            <span className={`${s.contact__description} ${source.contactInfoToggle}`}>
                                {node.contact_description}
                            </span>

                            <a href={`tel:${node.contact_number}`} className={`${s.contact__number} ${source.contactInfoToggle}`}>
                                <FontAwesomeIcon icon={faPhoneSquareAlt} />
                &nbsp;{node.contact_number}
                            </a>
                        </div>
                    </div>

                    <div className={s.burger}>
                        <button
                            type="button"
                            aria-expanded={isMenuOpen}
                            aria-label="Toggle navigation"
                            aria-controls="navbar-toggle-external-content"
                            onClick={() => updateIsMenuOpen(!isMenuOpen)}
                            className={`${s.burger__button} ${source.loginToggle}`}
                        >
                            <BurgerMenuIcon open={isMenuOpen} color={source.burgerColor} />
                        </button>
                    </div>
                </div>

                <div className={s.header__bottom} id="navbar-toggle-external-content">
                    <div className={`${s.header__bottom__wrap} ${source.bottomWrap}`}>
                        <nav className={`${s.siteNav} ${isMenuOpen ? s.siteNav___open : ''}`}>
                            <ul className={s.siteNav__list}>
                                {left.map(({ text, link, columns }) => {
                                    if (columns.length > 0) {
                                        return (
                                            <li key={`nav-left-column-${slugify(text)}`} className={source.navListToggle}>
                                                <DropDownList isHome={isHome} title={text} dropDownCaret columns={columns.length}>
                                                    <div>
                                                        {columns.map(({ slug, items }: NavColumn) => (
                                                            <div key={`nav-left-${slugify(text)}-column-${slug}`}>
                                                                {items.map(({ text, link, divider, style_toggle }: NavItem) => {
                                                                    if (!divider) {
                                                                        return (
                                                                            <ListItemLink
                                                                                key={`column-${slug}-${slugify(text)}`}
                                                                                onClick={() => updateIsMenuOpen(false)}
                                                                                to={link}
                                                                                altStyle={style_toggle || false}
                                                                            >
                                                                                {text}
                                                                            </ListItemLink>
                                                                        );
                                                                    }

                                                                    return (
                                                                        <li
                                                                            key={`column-${slug}-${slugify(text)}`}
                                                                            className={s.siteNav__list__divider}
                                                                        />
                                                                    );
                                                                })}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </DropDownList>
                                            </li>
                                        );
                                    }
                                    if (link === '/') {
                                        return (
                                            <li key={`nav-left-column-${slugify(text)}`} className={s.siteNav__list__item}>
                                                <Link
                                                    to={link}
                                                    className={`${s.siteNav__list__item} ${source.navTabToggle}`}
                                                    onClick={(): void => {
                                                        updateIsMenuOpen(false);
                                                        clearNamecheck();
                                                    }}
                                                >
                                                    {text}
                                                </Link>
                                            </li>
                                        );
                                    }
                                    return (
                                        <li key={`nav-left-column-${slugify(text)}`} className={s.siteNav__list__item}>
                                            <Link
                                                to={link}
                                                className={`${s.siteNav__list__item} ${source.navTabToggle}`}
                                                onClick={() => updateIsMenuOpen(false)}
                                            >
                                                {text}
                                            </Link>
                                        </li>
                                    );
                                })}
                            </ul>
                        </nav>

                        <nav className={`${s.userNav} ${isMenuOpen && s.siteNav___open}`}>
                            <ul className={s.userNav__list}>
                                {right.map(({ text, link, columns }) => {
                                    if (columns.length > 0) {
                                        return (
                                            <li key={`nav-right-column-${slugify(text)}`} className={source.navListToggle}>
                                                <DropDownList isHome={isHome} title={text} dropDownCaret columns={columns.length}>
                                                    <div>
                                                        {columns.map(({ slug, items }: NavColumn) => (
                                                            <div key={`nav-right-${slugify(text)}-column-${slug}`}>
                                                                {items.map(({ text, link, divider }: NavItem) => {
                                                                    if (!divider) {
                                                                        return (
                                                                            <ListItemLink
                                                                                key={`column-${slug}-${slugify(text)}`}
                                                                                to={link}
                                                                                onClick={() => updateIsMenuOpen(false)}
                                                                            >
                                                                                {text}
                                                                            </ListItemLink>
                                                                        );
                                                                    }

                                                                    return (
                                                                        <li
                                                                            key={`column-${slug}-${slugify(text)}`}
                                                                            className={s.userNav_list__divider}
                                                                        />
                                                                    );
                                                                })}
                                                            </div>
                                                        ))}
                                                    </div>
                                                </DropDownList>
                                            </li>
                                        );
                                    }

                                    return (
                                        <li key={`nav-right-column-${slugify(text)}`} className={source.navListToggle}>
                                            <Link
                                                to={link}
                                                className={`${s.userNav__list__item} ${source.navTabToggle}`}
                                                onClick={() => updateIsMenuOpen(false)}
                                            >
                                                {text}
                                            </Link>
                                        </li>
                                    );
                                })}
                                <>
                                    {isAuthenticated(cookie) ? (
                                        <li key="nav-right-column-account" className={source.navListToggle}>
                                            <DropDownList isHome={isHome} title="My Account" dropDownCaret columns={1}>
                                                <ListItemLink to={url.account('/account/')}>Dashboard</ListItemLink>
                                                <ListItemLink to={url.account('/payments/')}>Finances</ListItemLink>
                                                <ListItemLink to={url.account('/services/')}>Services</ListItemLink>
                                                <ListItemLink to={url.account('/companies/')}>Companies</ListItemLink>
                                                <ListItemLink to={url.account('/order-history/')}>Orders</ListItemLink>
                                                <ListItemLink to={url.account('/processed-mail/')}>Mail</ListItemLink>
                                                <ListItemLink to={url.account('/logout')}>Log Out</ListItemLink>
                                            </DropDownList>
                                        </li>
                                    ) : (
                                        <li key="nav-right-column-login" className={source.navListToggle}>
                                            <Link to={url.account('/login')} className={`${s.userNav__list__item} ${source.navTabToggle}`}>
                                                <FontAwesomeIcon className={s.userNav__list__icon} icon={faLock} />
                        Log In
                                            </Link>
                                        </li>
                                    )}
                                </>
                            </ul>
                        </nav>
                    </div>
                </div>
            </Wrapper>
        </header>
    );
};

export default connect((state: StoreProps) => ({
    state: { ...state.nameCheck },
}))(Navigation);
