import React from 'react';

import cardsHook from 'hooks/cards/cards.hook';

import s from './CardIcons.scss';

export default (): JSX.Element => {
    const cards = cardsHook();

    return (
        <div className={s.cardIcons}>
            {cards.map(({ node }): JSX.Element =>
                <img
                    src={node.logo.data.full_url}
                    alt={node.logo_alt}
                    title={node.logo_alt}
                    className={s.cardIcons__image}
                    key={node.slug}
                />,
            )}
        </div>
    );
};
