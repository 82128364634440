import React from 'react';

import { SocialIcon } from 'components/directus/social-icon/SocialIcon';
import { Props } from './SocialIcons.interface';

import s from './SocialIcons.scss';

export default ({ slugs }: Props): JSX.Element => (
    <div className={s.socialIcons}>
        {slugs.map((slug): JSX.Element => (
            <SocialIcon slug={slug} className={s.socialIcons__icon} key={`social-icon-${slug}`} />
        ))}
    </div>
);
