import React, { useEffect, useState, useRef, useCallback } from 'react';
import { createPortal } from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import OutsideClickHandler from 'react-outside-click-handler';

import { Props, ModalProps } from './Modal.interface';

import s from './Modal.scss';

const Modal = ({ children, portalRoot, el }: ModalProps) => {
    useEffect(() => {
        portalRoot.appendChild(el);

        return (() => {
            if (portalRoot) {
                portalRoot.removeChild(el);
            }
        });
    });

    return createPortal(children, el);
};

export default ({ children, fade = false, trigger }: Props): JSX.Element => {
    const [ isVisible, setIsVisible ] = useState(false);
    const triggerRef = useRef(null);

    const portalRoot = typeof document !== 'undefined' ? document.getElementById('portal') : null;
    const el = typeof document !== 'undefined' ? document.createElement('div') : null;

    if (!el) {
        return (<></>);
    }

    const handleEscape = useCallback((event) => {
        if (event.keyCode === 27) {
            setIsVisible(false);
        }
    }, []);

    useEffect(() => {
        if (isVisible) document.addEventListener('keydown', handleEscape, false);
        return () => {
            document.removeEventListener('keydown', handleEscape, false);
        };
    }, [ handleEscape, isVisible ]);

    return (
        <>
            <div
                onClick={() => setIsVisible(true)}
                ref={triggerRef}
                className={s.modalTrigger}
                role="presentation"
            >
                {trigger}
            </div>

            {isVisible && (
                <Modal portalRoot={portalRoot} el={el}>
                    <div className={`${s.modal} ${fade ? s.modal___fade : ''}`}>
                        <OutsideClickHandler onOutsideClick={() => setIsVisible(false)}>
                            <div className={s.modal__container}>
                                <div className={s.modal__header}>
                                    <FontAwesomeIcon
                                        onClick={() => setIsVisible(false)}
                                        icon={faTimes}
                                        className={s.modal__close}
                                    />
                                </div>
                                <div className={s.modal__body}>
                                    {children}
                                </div>
                            </div>
                        </OutsideClickHandler>
                    </div>
                </Modal>
            )}
        </>
    );
};
