import React from 'react';
import Img from 'gatsby-image';

import { Link } from 'components/common/link/Link';
import settingsHook from 'hooks/settings/settings.hook';

import s from './Company.scss';

export default (): JSX.Element => {
    const settings = settingsHook();

    return (
        <div className={s.company}>
            <Link to="/" title={settings.brand_name}>
                <Img
                    fluid={settings.logo.localFile.childImageSharp.fluid}
                    className={s.company__logo}
                    alt={`Logo for ${settings.brand_name}.`}
                    fadeIn
                />
            </Link>

            <div className={s.company__details}>
                <span>{settings.brand_name},&nbsp;<span> registered in England & Wales at</span></span>

                <div>
                    {settings.address}
                </div>

                <span>Company Nr: {settings.company_number}. &nbsp;</span>
                <span>VAT Registration Nr: {settings.vat_number}&nbsp;</span>
            </div>
        </div>
    );
};
