import React from 'react';

import settingsHook from 'hooks/settings/settings.hook';

import s from './Contacts.scss';

export default (): JSX.Element => {
    const year = new Date().getFullYear();
    const settings = settingsHook();

    return (
        <div className={s.contacts}>
            <p className={s.contacts__copyright}>Copyright {year} &copy; Rapid Formations &reg;</p>
            <div>
                <p className={s.contacts__phone}>
                    Tel:&nbsp;
                    <a href={`tel:${settings.telephone_number}`} className={s.contacts__link}>
                        {settings.telephone_number}
                    </a>
                </p>

                <p className={s.contacts__email}>
                    E-mail:&nbsp;
                    <a href={`mailto:${settings.email_address}`} className={s.contacts__link}>
                        {settings.email_address}
                    </a>
                </p>
            </div>
        </div>
    );
};
