import React from 'react';
import Img from 'gatsby-image';

import Markdown from 'components/common/markdown/Markdown';
import Modal from 'components/common/modal/Modal';
import accreditationsHook from 'hooks/accreditations/accreditations.hook';
import { getFieldBySlug } from 'utils/directus';
import { Props } from './Accreditation.interface';

export default ({ slug, className }: Props): JSX.Element => {
    const { node } = getFieldBySlug(slug, accreditationsHook());

    return (
        <div className={className}>
            <Modal
                trigger={
                    <Img
                        fluid={node.logo.localFile.childImageSharp.fluid}
                        alt={node.logo_alt}
                        style={{
                            height: `${node.logo.height / 1.15}px`,
                            width: `${node.logo.width / 1.15}px`,
                        }}
                    />
                }
            >
                <Markdown source={node.body} container />
            </Modal>
        </div>
    );
};
