import { Notification } from './efiling-parser.interface';
import { EfilingResponse } from '../efiling/efiling.interface';

import passIcon from '../../assets/images/namecheck/pass.png';
import failIcon from '../../assets/images/namecheck/fail.png';
import oopsIcon from '../../assets/images/namecheck/oops.png';

function wordsToString(words: any[], type: 'Word' | 'Name'): string {
    try {
        return words.map(obj => obj[ type ]).join(', ');
    } catch (e) {
        return '';
    }
}

export function errorNotification(companyName = 'Oops...'): Notification {
    return {
        companyName,
        iconSrc: oopsIcon,
        description: 'Something has went wrong. Please try again later.',
        status: 'ERROR',
    };
}

export function emptyNotification(companyName = 'Oops...'): Notification {
    return {
        companyName,
        iconSrc: oopsIcon,
        description: 'You forgot to type a company name.',
        status: 'ERROR',
    };
}

export function efilingParser(efilingResponse: EfilingResponse): Notification {
    const { NameStatus, NameRequested, SensitiveWords, SimilarNames } = efilingResponse;

    if (NameStatus === 'GREEN') {
        return {
            iconSrc: passIcon,
            companyName: NameRequested,
            description: 'Congratulations! This name is available.',
            status: 'GREEN',
        };
    }

    if (NameStatus === 'AMBER') {
        if (SensitiveWords.length > 0) {
            const words = wordsToString(SensitiveWords, 'Word');

            return {
                iconSrc: passIcon,
                companyName: NameRequested,
                description: `Please note: The word(s) ${words} is deemed sensitive.
          You may need to supply additional information to use it.`,
                status: 'GREEN',
            };
        }

        const names = wordsToString(SimilarNames, 'Name');

        return {
            iconSrc: failIcon,
            companyName: NameRequested,
            description: `Sorry, this company name is unavailable. Also unavailable is: ${names}`,
            status: 'RED',
        };
    }

    if (NameStatus === 'RED') {
        return {
            iconSrc: failIcon,
            companyName: NameRequested,
            description: 'Unfortunately, this name is not available for registration. Please select another.',
            status: 'RED',
        };
    }

    return errorNotification(NameRequested);
}
