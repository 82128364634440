import React from 'react';

import Accreditation from 'components/directus/accreditation/Accreditation';

import s from './Accreditations.scss';

export default (): JSX.Element => (
    <div className={s.accreditations}>
        <Accreditation slug="acra" className={s.accreditations__accreditation} />
        <Accreditation slug="living-wage-employer" className={s.accreditations__accreditation} />
        <Accreditation slug="mindful-employer" className={s.accreditations__accreditation} />
    </div>
);
