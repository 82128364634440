import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { faLayerGroup } from '@fortawesome/pro-solid-svg-icons';

import { Link } from 'components/common/link/Link';
import { Props } from './ListItemLink.interface';

import s from './ListItemLink.scss';

export const ListItemLink = ({ to, children, altStyle = false, onClick }: Props): JSX.Element => (
    <li className={s.item}>
        <Link to={to} className={s.item__link} onClick={onClick}>
            {altStyle ? (
                <span className={s.item__link__button}>
                    <FontAwesomeIcon icon={faLayerGroup} className={s.item__icon} />
                    {children}
                </span>
            ) : (
                <>
                    <FontAwesomeIcon icon={faChevronRight} size="xs" className={s.item__icon} />
                    {children}
                </>
            )}
        </Link>
    </li>
);
