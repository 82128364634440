import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';

import MainLayout from 'components/common/layouts/main-layout/MainLayout';
import OfferLayout from 'components/common/layouts/offer-layout/OfferLayout';
import HomeLayout from 'components/common/layouts/home-layout/HomeLayout';
import { PagePushContext } from 'providers/PagePushContext/PagePushContext';
import { cookieOptions } from 'utils/cookie';
import { Props } from './index.interface';

export default ({ children, pageContext }: Props): JSX.Element => {
    const [ isPushed, setIsPushed ] = useState<boolean>(false);
    const value = { isPushed, setIsPushed };
    const [ cookies, setCookie ] = useCookies();

    useEffect(() => {
        if (typeof window !== undefined) {
            const urlParams = new URLSearchParams(window.location.search);
            const clickId = urlParams.get('irclickid');
            setCookie('clickid', clickId, cookieOptions(60));
        }
    }, []);

    useEffect(() => {
        if (typeof window !== undefined) window.ire('identify', { customerId: '', customerEmail: '' });
    }, []);

    const getLayout = () => {
        switch (pageContext.layout) {
        case 'home':
            return <HomeLayout>{children}</HomeLayout>;
            break;
        case 'offer':
            return <OfferLayout>{children}</OfferLayout>;
            break;
        default:
            return <MainLayout>{children}</MainLayout>;
        }
    };

    return (
        <PagePushContext.Provider value={value}>
            { getLayout() }
        </PagePushContext.Provider>
    );
};
