import React from 'react';

import { Props } from './Wrapper.interface';

import s from './Wrapper.scss';

export const Wrapper = ({ children, className }: Props): JSX.Element => (
    <div className={s.wrapper}>
        <div className={s.wrapper__container}>
            <div className={`${s.wrapper__content} ${className || ''}`}>
                {children}
            </div>
        </div>
    </div>
);
