import React from 'react';

import { Link } from 'components/common/link/Link';
import { Props } from './ListItem.interface';

import s from './ListItem.scss';

export const ListItem = ({ to, children }: Props): JSX.Element => (
    <li className={s.item}>
        <Link to={to} className={s.item__link}>
            {children}
        </Link>
    </li>
);
