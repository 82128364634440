import React, { useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

import Banner from 'components/common/banner/Banner';
import CookieConsent from 'components/common/cookie-consent/CookieConsent';
import Navigation from 'components/directus/navigation/Navigation';
import MobileCallUs from 'components/common/mobile-callus/MobileCallUs';
import { Footer } from 'components/directus/footer/Footer';
import { PagePushContext } from 'providers/PagePushContext/PagePushContext';
import { helmet } from 'utils/helmet';
import { Props } from '../Layouts.interface';

import x from '../../banner/Banner.scss';
import s from '../Layouts.scss';

export default ({ children }: Props): JSX.Element => {
    const { isPushed, setIsPushed } = useContext(PagePushContext);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsPushed(true);
        }, 1000);
        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            <MobileCallUs />
            <div className={s.app}>
                <Banner color="black" className={`${x.bannerPusher} ${isPushed ? x.bannerPushed : ''}`}>
                    <div className={x.banner__actions}>
                        <FontAwesomeIcon
                            onClick={() => setIsPushed(false)}
                            icon={faTimes}
                            className={x.banner__close}
                        />
                    </div>
                </Banner>
                <div className={`${x.mpPusher} ${isPushed ? x.mpPushed : ''}`}>
                    <Navigation isHome={false} />
                    {children}
                    <Helmet {...helmet} />
                    <Footer />
                    <CookieConsent />
                </div>
            </div>
        </>
    );
};
