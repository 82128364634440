import { graphql, useStaticQuery } from 'gatsby';
import { AllDirectusPrice, PriceEdges } from './price.interface';

export default (): PriceEdges[] => {
    const { allDirectusPrice } = useStaticQuery<AllDirectusPrice>(graphql`
    query {
      allDirectusPrice {
        edges {
          node {
            directusId
            slug
            name
            value
            buy_link
            page_url
          }
        }
      }
    }
  `);

    return allDirectusPrice.edges;
};
