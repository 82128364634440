import React from 'react';

import Button from 'components/common/button/Button';
import BannerHook from 'hooks/special-offer-banner/special-offer-banner.hook';
import { capitalize } from 'utils/capitalize';
import { Props } from './Banner.interface';

import s from './Banner.scss';

export default ({ children, color, className }: Props): JSX.Element => {
    const colorClass = color ? s[ `banner__color${capitalize(color)}` ] : s.bannerWhite;
    const banner = BannerHook();

    return (
        <div
            key="banner"
            className={`${s.banner} ${colorClass} ${className || ''}`}
        >
            <div className={s.banner__wrapper}>
                <div className={s.banner__body}>
                    <em>{banner.emphasis_text}</em>
                    <span>{banner.secondary_text}</span>
                    <Button
                        size="small"
                        role="success"
                        className={s.banner__btnPrimary}
                        to={banner.button_link}
                    >
                        {banner.button_text}
                    </Button>
                    {children}
                </div>
            </div>
        </div>
    );
};
