import { EfilingResponse } from './efiling.interface';

export const checkCompanyName = async (name: string): Promise<EfilingResponse> => {
    if (!name) throw new Error('Company name cannot be empty.');
    const response = await fetch(`${process.env.GATSBY_NAMECHECK_SUBMIT_URL}${encodeURIComponent(name)}`, {
        method: 'GET',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
        referrer: 'no-referrer',
    });

    const { data } = await response.json();

    return data;
};
