import { graphql, useStaticQuery } from 'gatsby';
import { DirectusSettings, Settings } from './settings.interface';

export default (): Settings => {
    const { directusSetting } = useStaticQuery<DirectusSettings>(graphql`
    query {
      directusSetting {
        brand_name
        telephone_number
        address
        email_address
        company_number
        vat_number
        logo {
          data {
            full_url
          }
          width
          height
          localFile {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  `);

    return directusSetting;
};
