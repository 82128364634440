const ACCOUNT_URL = 'https://client.rapidformations.co.uk';

export const url = {
    account: (uri = '/'): string => {
        if (uri[ 0 ] !== '/') {
            throw new Error('Account URL must start with a "/".');
        }

        return `${ACCOUNT_URL}${uri}`;
    },
};
