import { Field } from './get-field-by-slug.interface';

export function getFieldBySlug<T = unknown & Field>(slug: string, fields: (T & Field)[]): T {
    if (fields.length === 0) {
        throw new Error(`Cannot get collection fields by slug "${slug}" when fields array is empty.`);
    }

    const field = fields.find(({ node }) =>
        node.slug === slug,
    );

    if (field === undefined) {
        throw new Error(`Collection field with slug "${slug}" does not exist.`);
    }

    return field;
}
